import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import {close} from 'ionicons/icons';
import {useEffect, useState} from 'react';
import {submitSurvey} from '../../Api/api.orders';
import {nameSearch} from '../../Api/api.search';
import Vendor from '../../Domains/Vendor/VendorClass';
import {VendorItem} from '../../Domains/Vendor/VendorItem';
import UseDebounce from '../../Helpers/Hooks/UseDebounce';
import MWButton from '../MWButton/MWButton';

const MarketingSourceModal = ({
                                ...props
                              }: {
  onDismiss: Function;
  orderId: number;
}) => {
  const [marketingSource, setMarketingSource] = useState('');
  const [selectedVendor, setSelectedVendor] =
    useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [showNameSearch, setShowNameSearch] = useState(false);
  const [showMessage] = useIonAlert();
  const starter: string = '';
  const [term, setTerm] = useState(starter);
  const searchTerm = UseDebounce(term, 1000);
  const [results, setResults] =
    useState<Array<Vendor> | null>(null);

  const localSubmitSurvey = () => {
    submitSurvey(props.orderId, 'MarketingSource', marketingSource, null, selectedVendor).then();
    props.onDismiss();
  };

  useEffect(() => {
    if (marketingSource !== 'From a Farmer/Vendor I Support') {
      setSelectedVendor(null);
      setShowNameSearch(false);
    } else {
      setShowNameSearch(true);

    }
  }, [marketingSource]);

  useEffect(() => {
    if (searchTerm.length > 1) {
      setLoading(true);
      nameSearch(searchTerm).then((searchResults: any) => {
        setResults(searchResults.results);
        setLoading(false);

      }).catch(() => {
        setLoading(false);
        showMessage({
          message: 'Sorry, we could not find any vendors at this time',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
                setShowNameSearch(false);
              }
            }
          ]
        }).then();
      });
    } else {
      setSelectedVendor(null);
      setResults(null);
      setLoading(false);

    }

  }, [searchTerm, showMessage]);

  const handleSetMarketingSource = (value: string) => {
    setShowOther(false);
    setMarketingSource(value);
  };

  return (
    <div
      className='h-full md:rounded-lg md:h-47.25rem overflow-y-auto bg-white flex flex-col justify-center'
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              aria-label='Close'
              id='close-button'
              fill='clear'
              slot='start'
              onClick={() => props.onDismiss()}
            >
              <IonIcon
                aria-describedby='close-button'
                icon={close}
                slot='icon-only'
              />
            </IonButton>
          </IonButtons>
          <IonTitle className='w-full overflow-visible px-0'>
            Survey
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <hr/>

      <div className='overflow-y-auto h-full relative p-4'>
        <h2 className='text-2xl font-normal leading-normal mt-0 mb-2'>
          How did you hear about us?
        </h2>
        <section className='flex-col space-y-2'>
          <div>
            <input
              type='radio'
              name='marketing_source'
              value='From a Farmer/Vendor I Support'
              id='marketingVendor'
              onChange={(e) => {
                handleSetMarketingSource(e.target.value);
              }}
              className='mr-2 mb-2'
            />

            <label htmlFor='marketingVendor'>
              From a farmer/vendor I support
            </label>

            {showNameSearch &&
              <div>
                <input
                  type='text'
                  className='p-2 mb-4 bg-gray-100 rounded-md'
                  name='marketing_source_name'
                  placeholder='Seach for vendor'
                  id='marketingFriendName'
                  onChange={(e) => {
                    setTerm(e.target.value);
                    setSelectedVendor(null)
                  }}
                />

                {term !== '' &&
                  <div className={`max-h-40  overflow-y-auto pt-1 pb-2 px-1`}>
                    {loading && (
                      <div className='pt-4 px-4 text-center flex items-center space-x-2 justify-center'>
                        <IonSpinner/>
                        <span className='font-medium text-gray-500'>Loading...</span>
                      </div>
                    )}
                    {
                      results?.filter(item => item.state !== null).slice(0, 25)
                        .map((item: Vendor, index: number) => {
                          return (
                            <VendorItem
                              key={index}
                              vendor={item}
                              className={
                                (selectedVendor !== item.id && selectedVendor !== null)
                                  ? 'hidden'
                                  : ''}
                              onClick={() => {
                                setSelectedVendor(item.id);
                              }}
                            />
                          );
                        })
                    }
                  </div>
                }
              </div>
            }
          </div>

          <div>
            <input
              type='radio'
              name='marketing_source'
              value='From a Friend or Colleague'
              id='marketingFriend'
              onChange={(e) => {
                handleSetMarketingSource(e.target.value);
              }}
              className='mr-2 mb-2'
            />
            <label htmlFor='marketingFriend'>From a friend or colleague</label>
          </div>

          <div>
            <input
              type='radio'
              name='marketing_source'
              value='AI Recommendation'
              id='marketingAIRecommendation'
              onChange={(e) => {
                handleSetMarketingSource(e.target.value);
              }}
              className='mr-2 mb-2'
            />
            <label htmlFor='marketingAIRecommendation'>AI recommendation</label>
          </div>

          <div>
            <input
              type='radio'
              name='marketing_source'
              value='Online Search'
              id='marketingOnlineSearch'
              onChange={(e) => {
                handleSetMarketingSource(e.target.value);
              }}
              className='mr-2 mb-2'
            />
            <label htmlFor='marketingOnlineSearch'>Online search</label>
          </div>

          <div>
            <input
              type='radio'
              name='marketing_source'
              value='News Story'
              id='marketingNews'
              onChange={(e) => {
                handleSetMarketingSource(e.target.value);
              }}
              className='mr-2 mb-2'
            />
            <label htmlFor='marketingNews'>News story</label>
          </div>

          <div>
            <input
              type='radio'
              name='marketing_source'
              value='Facebook / Instagram'
              id='marketingFacebookInstagram'
              onChange={(e) => {
                handleSetMarketingSource(e.target.value);
              }}
              className='mr-2 mb-2'
            />
            <label htmlFor='marketingFacebookInstagram'>Facebook/Instagram</label>
          </div>

          <div>
            <input
              type='radio'
              name='marketing_source'
              value='Flyer or Brochure'
              id='marketingFlyer'
              onChange={(e) => {
                handleSetMarketingSource(e.target.value);
              }}
              className='mr-2 mb-2'
            />
            <label htmlFor='marketingFlyer'>Flyer or brochure</label>
          </div>

          <div>

            <input
              type='radio'
              name='marketing_source'
              value='At a store or restaurant'
              id='marketingStoreOrRestaurant'
              onChange={(e) => {
                handleSetMarketingSource(e.target.value);
              }}
              className='mr-2 mb-2'
            />
            <label htmlFor='marketingStoreOrRestaurant'>At a store or restaurant</label>
          </div>

          <div>

            <input
              type='radio'
              name='marketing_source'
              value='Online Banner Ads'
              id='marketingOnlineBannerAds'
              onChange={(e) => {
                handleSetMarketingSource(e.target.value);
              }}
              className='mr-2 mb-2'
            />
            <label htmlFor='marketingOnlineBannerAds'>Online banner ads</label>
          </div>

          <div>
            <input
              type='radio'
              name='marketing_source'
              value='Other'
              id='marketingOther'
              onChange={(e) => {
                setShowOther(e.target.checked);
              }}
              className='mr-2 mb-2'
            />
            <label htmlFor='marketingOther'>Other</label>
            {showOther && (
              <input
                type='text'
                className='w-full shadow appearance-none border rounded w-50 py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline'
                onChange={(e) => {
                  setMarketingSource(e.target.value);
                }}
              />
            )}
          </div>
        </section>
      </div>
      <div className='flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md'>
        {
          <MWButton
            title='Submit'
            onClick={localSubmitSurvey}
            bg-gray-200
            className='justify-center w-full text-sm text-white bg-green-600 rounded-lg h-11 m-0'
            disabled={(marketingSource !== 'From a Farmer/Vendor I Support' && marketingSource === '')
              || (marketingSource === 'From a Farmer/Vendor I Support' && selectedVendor === null)}
          >
            Submit
          </MWButton>
        }
      </div>
    </div>
  );
};
export default MarketingSourceModal;
